.App {
  display: flex;
  flex-direction: column;
  width: 360px;
  min-height: 200px;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
}

.Title-Box {
  padding: 8px 24px 0px 24px;
}

.Content-Box {
  padding: 0px 24px 0px 24px;
}

.Form-Input-Box {
  padding: 16px 24px 16px 24px;
}

.Form-Button-Box {
  padding: 16px 24px 16px 24px;
  display: flex;
  justify-content: flex-end;
}

.Logo {
  display: flex;
  padding: 16px 24px 8px 24px;
}

.Logo-Image {
  height: 25px;
}
